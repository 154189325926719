<template lang="pug">
.page
  .item(v-for="i in routerList")
    a-button(type="primary" @click="$router.push(`${i.path}`)")
      | {{i.name}}

</template>

<script setup>
import { onMounted } from 'vue'

onMounted(() => {})

const routerList = [
  { path: '/editor/workspace', name: '模板编辑器' },
  { path: '/asset', name: '模板库' },
  { path: '/retouch', name: '图片编辑器' },
  { path: '/debug', name: 'debug' }
]
</script>

<style lang="less" scoped>
.page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .item {
    margin: 20px 0;
  }
}
</style>
